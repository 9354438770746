<template>
  <div>
    <!-- Hero -->
    <base-page-heading title="Raport lucrari">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Lucrari</b-breadcrumb-item>
          <b-breadcrumb-item active text="Raport"></b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <!-- Page Content -->
    <div class="content">
      <!-- Full Table -->
      <base-block title="Raport" subtitle="Despre lucrari in service">
        <b-row>
          <b-col xl="8">
            <Search v-bind:search="search" />
          </b-col>
          <b-col xl="4">
            <b-row>
              <b-col xl="9" class="text-right text-primary">Total de plata client:</b-col>
              <b-col v-if="reportWorkReport" xl="3" class="text-right text-primary font-weight-bold">{{reportWorkReport.clientPaymentSum.toFixed(2)}} RON</b-col>
              <b-col v-if="!reportWorkReport" xl="3" class="text-right text-primary font-weight-bold"></b-col>
              <b-col xl="9" class="text-right text-warning">Total piese folosite cu tva:</b-col>
              <b-col v-if="reportWorkReport" xl="3" class="text-right text-warning font-weight-bold">{{reportWorkReport.investmentSum.toFixed(2)}} RON</b-col>
              <b-col v-if="!reportWorkReport" xl="3" class="text-right text-warning font-weight-bold"></b-col>
              <b-col xl="9" class="text-right text-success">Total profit:</b-col>
              <b-col v-if="reportWorkReport" xl="3" class="text-right text-success font-weight-bold">{{reportWorkReport.profitSum.toFixed(2)}} RON</b-col>
              <b-col v-if="!reportWorkReport" xl="3" class="text-right text-success font-weight-bold"></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-table-simple responsive bordered striped table-class="table-vcenter">
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="d-sm-none" style="width: 70%;">Report</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 12%;">Data</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 16%;">Client</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 15%;">Echipament</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 10%;">Cost</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 17%">Tehnician</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 15%;">Stare</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 15%;">Termen</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="works.length <= 0 && loaded">
              <b-td class="d-md-none text-center">
                <b-alert variant="warning" show class="mt-3">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
              <b-td colspan="8" class="d-none d-sm-table-cell text-center">
                <b-alert variant="warning" show class="mt-3">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
            </b-tr>
            <b-tr v-for="work in works" :key="work._id">
              <b-td class="d-sm-none" style="width: 70%;">
                <b-row>
                  <b-col class="col-6 mb-2">
                    {{ getDateStr(work.node.createdAt) }}
                  </b-col>
                  <b-col class="col-6 mb-2 text-right text-primary">
                    {{ work.node.clientName }}
                  </b-col>
                  <b-col class="col-6 mb-2">
                    {{ work.node.productName }}
                  </b-col>
                  <b-col class="col-6 mb-2 text-right">
                    {{ work.node.priceModel.totalPrice.toFixed(2) }} RON
                  </b-col>
                  <b-col class="col-6 mb-2">
                    {{ work.node.createdBy.name }}
                  </b-col>
                  <b-col class="col-6 mb-2 text-right">
                    {{getDateStr(work.node.resolveTermDate)}}
                  </b-col>
                </b-row>
                <div class="text-center">
                  <span class="font-weight-bold" v-bind:class="'text-'+work.node.statusStyle">{{ work.node.statusLabel }}</span>
                </div>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span class="text-dark font-weight-light">{{ getDateStr(work.node.createdAt) }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span class="text-primary font-weight-bolder">{{ work.node.clientName }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span class="text-dark font-weight-light">{{ work.node.productName }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm text-right">
                <span class="text-dark font-weight-bold">{{ work.node.priceModel.totalPrice.toFixed(2) }} RON</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span class="text-dark font-weight-light">{{ work.node.createdBy.name }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                <span class="font-weight-bold" v-bind:class="'text-'+work.node.statusStyle">{{ work.node.statusLabel }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                <span class="text-dark font-weight-bold">{{getDateStr(work.node.resolveTermDate)}}</span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </base-block>
    </div>
    <!-- END Page Content -->
    <infinite-loading @infinite="loadMore" ref="infiniteLoading">
      <div slot="spinner"><b-spinner variant="primary" label="Loading..."></b-spinner></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import Search from "./partial/Search";
import InfiniteLoading from "vue-infinite-loading";
import {getDateStr} from "../../../constants/helpers/common-helper";
import {getCode} from "../../../constants/helpers/service-helper";
import {
  SERVICE_WORK_REPORT_LIST
} from "../../../constants/service-model-graphql";
import {ITEM_NUMBER_PER_PAGE} from "../../../constants/settings";
import {fleshErrorMessage} from "../../../error/server-error";

export default {
  name: "ServiceReport",
  components: {InfiniteLoading,Search},
  data() {
    return {
      search: (this.$route.query.search) ? JSON.parse(atob(this.$route.query.search)) : null,
      works: [],
      cursor: null,
      pageInfo: null,
      loaded: false,
      workNumber: 0,
      reportWorkReport: null,
    }
  },
  watch: {
    $route() {
      this.loaded = false;
      this.search = (this.$route.query.search) ? JSON.parse(atob(this.$route.query.search)) : null;
      this.works = [];
      this.cursor = null;
      this.pageInfo = null;
      this.workNumber = 0;
      this.$refs.infiniteLoading.stateChanger.reset();
      this.$refs.infiniteLoading.attemptLoad();
    }
  },
  methods: {
    loadMore ($state) {
      let start = new Date();
      start = new Date(start.getFullYear(), start.getMonth(), 1);
      let end = new Date();
      this.$apollo.query({
        query: SERVICE_WORK_REPORT_LIST,
        variables: {
          first: ITEM_NUMBER_PER_PAGE,
          after: (this.pageInfo) ? this.pageInfo.endCursor : null,
          start: (this.search && this.search.fromDate) ? this.search.fromDate : start,
          end: (this.search && this.search.toDate) ? this.search.toDate : end,
        },
        fetchPolicy: 'no-cache',
      }).then((result) => {
        this.pageInfo = result.data.services.pageInfo;
        this.works.push(...result.data.services.edges);
        this.pageInfo.hasNextPage ? $state.loaded() : $state.complete();
        this.loaded = true;
        this.workNumber = result.data.services.totalCount;
        this.reportWorkReport = result.data.reportWorkReport
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    getDateStr: getDateStr,
    getCode: getCode,
  }
}
</script>