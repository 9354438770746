<template>
  <div>
      <base-block content-class="p-0">
        <div class="font-size-sm push mb-0">
          <div v-if="dataLoad" class="text-center">
            <b-spinner variant="primary" label="Loading..."></b-spinner>
          </div>
          <b-form v-if="!dataLoad" @submit.stop.prevent="onBaseSearchSubmit">
            <b-row class="form-group">
              <b-col xl="3" class="mt-3">
                <flat-pickr size="md" class="form-control form-control-alt border" id="fromDate" placeholder="De la" v-model="$v.searchForm.fromDate.$model" :state="!$v.searchForm.fromDate.$error && null" v-bind:class="{'is-invalid': $v.searchForm.fromDate.$error}" @on-change="makeAttention"></flat-pickr>
              </b-col>
              <b-col xl="3" class="mt-3">
                <flat-pickr size="md" class="form-control form-control-alt border" id="toDate" placeholder="Pana la" v-model="$v.searchForm.toDate.$model" :state="!$v.searchForm.toDate.$error && null" v-bind:class="{'is-invalid': $v.searchForm.toDate.$error}" @on-change="makeAttention"></flat-pickr>
              </b-col>
              <b-col xl="6">
                <b-button v-bind:class="{'animated wobble': searchClickAttention}" size="md" type="submit" variant="primary" class="mt-3">
                  <small><i class="fa fa-fw fa-search mr-1"></i> Cautare</small>
                </b-button>
                <router-link v-if="searchTerm" class="btn btn-md btn-warning ml-2 mt-3" to="/service/report" active-class="" v-on:click.native="onClearFilters">
                  <small><i class="fa fa-fw fa-times-circle mr-1"></i> Reseteaza</small>
                </router-link>
                <b-button size="md" type="submit" class="ml-2 mt-3" variant="success" @click="printServiceReport">
                  <small><i class="fa fa-fw fa-print mr-1"></i> Tipareste</small>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </base-block>
  </div>
</template>
<style lang="scss">
// Flatpickr + Custom overrides
@import '~flatpickr/dist/flatpickr.css';
@import './src/assets/scss/vendor/flatpickr';
</style>
<script>
import flatPickr from "vue-flatpickr-component";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import {fleshErrorMessage} from "../../../../error/server-error";
import {PRINT_SERVICE_REPORT} from "../../../../constants/service-model-graphql";

export default {
  name: "Search",
  components: {flatPickr},
  mixins: [validationMixin],
  props: {
    search: null
  },
  data() {
    return {
      dataLoad: true,
      searchClickAttention: null,
      categories: [],
      searchTerm: null,
      searchForm: {
        fromDate: null,
        toDate: null,
      },
    }
  },
  watch: {
    $route() {
      //this.loaded = false;
      this.searchTerm = (this.$route.query.search) ? JSON.parse(atob(this.$route.query.search)) : null;
    }
  },
  validations: {
    searchForm: {
      fromDate: {
        required
      },
      toDate: {
        required
      },
    }
  },
  methods: {
    makeAttention() {
      this.searchClickAttention = 'animated wobble';
      setTimeout(() => this.searchClickAttention = null, 1200);
    },
    onBaseSearchSubmit() {
      this.$v.searchForm.$touch();
      if (this.$v.searchForm.$anyError) {
        return;
      }
      let path = "/service/report";
      if (this.searchForm.fromDate || this.searchForm.toDate) {
        path = "/service/report?search="+btoa(JSON.stringify(this.searchForm));
      }
      this.$router
          .push(path)
          .catch(error => {
            error = {};
            fleshErrorMessage(error, this);
          });
    },
    onClearFilters() {
      this.setDefaultValuesForForm();
    },
    setDefaultValuesForForm() {
      let date = new Date();
      this.searchForm = {
        fromDate: new Date(date.getFullYear(), date.getMonth(), 1),
        toDate: date,
      };
    },
    printServiceReport() {
      this.searchTerm = (this.$route.query.search) ? JSON.parse(atob(this.$route.query.search)) : null;
      let start = new Date();
      let end = new Date(start.getFullYear(), start.getMonth(), 1);
      this.$apollo.mutate({
        mutation: PRINT_SERVICE_REPORT,
        variables: {
          start: (this.searchTerm && this.searchTerm.fromDate) ? this.searchTerm.fromDate : start,
          end: (this.searchTerm && this.searchTerm.toDate) ? this.searchTerm.toDate : end,
        }
      }).then((response) => {
        this.$print(response.data.createPrintServiceReport.printServiceReport.response);
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
  },
  mounted() {
    if (this.search) {
      this.searchForm = this.search;
      this.dataLoad = false;
    } else {
      this.setDefaultValuesForForm();
      this.dataLoad = false;
    }
  }
}
</script>